<template>
  <div v-if="search.data.filter_data.length" class="c-amend-search__choice-buttons">
      <div
          :key="index"
          v-for="(filter, index) in search.data.filter_data.filter((filter) => filter.is_parent)"
          @click="resetFiltersLocal"
          class="o-search__type">
          <label>
              <input type="radio" 
                     :value="filter.id" 
                     v-model="search.data.selected_parent_filter" 
                     @change="updateParentSelection(filter)" />

              <span v-html="filter.name"></span>
          </label>
      </div>
  </div>

  <div v-if="search.data.selected_parent_filter && search.data.filter_data.length"
      :key="index"
      v-for="(childFilter, index) in search.data.filter_data.filter(
          (filter) => filter.parent_id == search.data.selected_parent_filter,
      )"
      class="c-amend-search__type-option">
      <label>
          <input type="checkbox" 
                 :value="childFilter.id" 
                 v-model="search.data.selected_child_filters" 
                 @change="resetFilters" />

          <span v-html="childFilter.name"></span>
      </label>
  </div>
</template>

<script setup>
  const search = useSearchStore();
  const booking = useBookingStore()

  const resetFilters = () => {
      search.data.selected_parks = [];
      search.data.selected_date = [];
      search.data.selected_nights = null

      // Reset all guest numbers to 0
      search.data.selected_guests.forEach(guest => {
          guest.number = 0;
      });

      // Optional: sync pets value if you store it separately
      booking.order.pets = 0;
  };



  const resetFiltersLocal = () => {
      search.data.selected_child_filters = [];
  }

  const updateParentSelection = (filter) => {
      search.data.selected_parent_filter = filter.id; // Save ID
      search.data.selected_parent_slug = filter.slug; // Save Slug
      
  }
</script>